import React, { useEffect, useState } from "react";

import DashboardLayer from "../DashboardLayer/DashbordLayer";
import DashboardList from "../DashboardList/DashboardList";

import { CardInterface, DashboardListInterface } from '../../types/types';
import { CircularProgress, Box } from "@mui/material";

import Fetcher from "../../utils/Fetcher";

const fetcher = new Fetcher();

const CardsList = () => {
  const [cardsList, setCardsList] = useState<DashboardListInterface[] | null>(null);
  const [filteredList, setFilteredList] = useState<DashboardListInterface[] | null>(null);

  useEffect(() => {
    const fetchCards = async () => {
      const cards = await fetcher.fetchData<CardInterface[]>("/admin/card");
      const mappedCards = mapCardsToList(cards);
      setCardsList(mappedCards);
      setFilteredList(mappedCards)
    };

    fetchCards();
  }, []);

  const mapCardsToList = (cards: CardInterface[]): DashboardListInterface[] => {
    return cards.map(card => ({
      name: card.name,
      path: `/card/${card.id}`,
      iconUrl: card.image.url,
    }))
  }

  if (!cardsList || !filteredList) {
    return (
      <DashboardLayer withTopBar={false}>
        <Box display="flex" justifyContent="center" marginTop="5rem">
          <CircularProgress size={150} />
        </Box>
      </DashboardLayer>
    )
  }

  return (
    <DashboardLayer searchList={cardsList} setFilteredList={setFilteredList} newEntryPath="/add/card">
      <DashboardList list={filteredList} />
    </DashboardLayer>
  );
};

export default CardsList;
