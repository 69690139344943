import React, { useState, useEffect, FormEvent } from "react";

import NewEntryForm from "../NewEntryForm/NewEntryForm";

import Fetcher from "../../utils/Fetcher";
import { AttributeType, AttributesEnum } from "../../types/types";
import DashboardLayer from "../DashboardLayer/DashbordLayer";
import { CircularProgress, Box, Snackbar } from "@mui/material";

const fetcher = new Fetcher();

const AddNewCardForm = () => {
  const [attributes, setAttributes] = useState<AttributeType | null>(null);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<Record<string, any>>({
    name: "",
    description: "",
    image: null,
    audio: null,
  });

  const [notificationMessage, setNotificationMessage] = useState<string>("")

  const submitNewCard = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { name, description, attributes, image, audio } =
      mapFormValuesToBody(formValues);
    const cardData = new FormData();

    cardData.append("name", name);
    cardData.append("description", description);
    cardData.append("attributes", JSON.stringify(attributes));
    cardData.append("image", image);
    cardData.append("audio", audio);
    setIsAdding(true);

    try {
      await fetcher.postData("/admin/card", cardData);
      setNotificationMessage("Successfully added card")
      resetFormValues();
    } catch (e) {
      setNotificationMessage("Failed to add your card");
      console.log(e);
    }

    setIsAdding(false);
  };

  const resetFormValues = () => {
    setFormValues({
      name: "",
      description: "",
      image: null,
      audio: null,
      attributes: attributes ? prepareDefaultAttributeValues(attributes) : null
    })
  }

  const mapFormValuesToBody = (formValues: Record<string, any>) => {
    const { name, description, image, audio } = formValues;
    const attributes: Record<string, number> = {};
    Object.values(AttributesEnum).map(
      (attributeName) =>
        (attributes[`${attributeName}Id`] = formValues[attributeName])
    );

    return {
      name,
      description,
      attributes,
      image,
      audio,
    };
  };

  const prepareDefaultAttributeValues = (attributes: AttributeType) => {
    const result: Record<string, string | number> = {};
    Object.keys(attributes).forEach(
      (attributeName) =>
        (result[attributeName] = attributes[attributeName][0].id)
    );
    return result;
  };

  useEffect(() => {
    const fetchAttributes = async () => {
      const data = await fetcher.fetchData<AttributeType>("/admin/attribute");
      setAttributes(data);
      setFormValues({ ...formValues, ...prepareDefaultAttributeValues(data) });
    };

    fetchAttributes();
  }, []);

  if (!attributes || isAdding) {
    return (
      <DashboardLayer withTopBar={false}>
        <Box display="flex" justifyContent="center" marginTop="5rem">
          <CircularProgress size={150} />
        </Box>
      </DashboardLayer>
    );
  }

  return (
    <DashboardLayer withTopBar={false}>
      <NewEntryForm
        submitFunction={submitNewCard}
        formValues={formValues}
        setFormValues={setFormValues}
        fields={[
          {
            inputType: "stringInput",
            name: "Name",
            key: "name",
          },
          {
            inputType: "stringInput",
            name: "Description",
            key: "description",
          },
          ...Object.keys(attributes).map((attributeName) => ({
            key: attributeName,
            inputType: "select",
            name: attributeName,
            values: attributes[attributeName],
          })),
          {
            inputType: "fileInput",
            name: "Image",
            key: "image",
          },
          {
            inputType: "fileInput",
            name: "Audio",
            key: "audio",
          },
        ]}
      />
      <Snackbar
        open={notificationMessage.length !== 0}
        onClose={() => setNotificationMessage("")}
        message={notificationMessage}
        autoHideDuration={6000}
      />
    </DashboardLayer>
  );
};

export default AddNewCardForm;
